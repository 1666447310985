var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"stle":"","loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 30, 50],
      showFirstLastPage: false,
      'items-per-page-text': _vm.$t('table.messages.items_per_page'),
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.messages.page'))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"top",fn:function(){return [_c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center blue--text",attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.$t('messages.date_range'))+" ")]),_c('v-col',{staticClass:"d-flex align-center mt-3",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":""},model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"align-self-center mr-9"},[_c('v-text-field',{attrs:{"id":"search","name":"search pa-0 ","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{staticClass:"d-flex align-end p-0",attrs:{"cols":"2"}},[_c('div',{staticClass:"mb-9"},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.onSearch}},[_c('span',{staticClass:"pr-5 pl-5 font-weight-bold text-h5"},[_vm._v(_vm._s(_vm.$t('table.messages.search')))])])],1)])],1)],1)]},proxy:true},{key:"item.index",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getIndex(props.index))+" ")]}},{key:"item.overTime",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.totalWorkHourPerWeek - item.maxWorktime))])]}},{key:"item.laborSituation",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"green--text",attrs:{"color":"#C9F7F5","small":"","dark":"","min-width":"100px"},on:{"click":function($event){return _vm.checkDetail(item)}}},[_vm._v(" "+_vm._s(_vm.$t('button.detail'))+" ")])]}},{key:"footer.prepend",fn:function(){return undefined},proxy:true}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('table.messages.no_data'))+" ")])],2),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }