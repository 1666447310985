<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      class="elevation-1"
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30, 50],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template
        v-slot:footer.page-text
        class="mr-0"
      >
        {{ $t('table.messages.page') }}
        {{ options.page }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col
              cols="1"
              class="d-flex align-center justify-center blue--text"
            >
              {{ $t('messages.date_range') }}
            </v-col>
            <v-col
              cols="3"
              class="d-flex align-center mt-3"
            >
              <v-text-field
                v-model="timeRange"
                readonly
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col cols="3">
              <div class="align-self-center mr-9">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0 "
                  outlined
                />
              </div>
            </v-col>

            <v-col
              cols="2"
              class="d-flex align-end p-0"
            >
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="onSearch"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">{{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ getIndex(props.index) }}
      </template>
      <template v-slot:item.overTime="{item}">
        <span class="red--text">{{ item.totalWorkHourPerWeek - item.maxWorktime }}</span>
      </template>
      <template v-slot:item.laborSituation="{item}">
        <v-btn
          color="#C9F7F5"
          small
          dark
          min-width="100px"
          class="green--text"
          @click="checkDetail(item)"
        >
          {{ $t('button.detail') }}
        </v-btn>
      </template>
      <template v-slot:footer.prepend />
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  export default {
    name: 'ParttimeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        isSelectingFile: true,
        fileIsValid: true,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '6rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'center',
            value: 'manageId',
            class: 'font-weight-bold',
            width: '10rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.name'),
            align: 'center',
            value: 'name',
            class: 'font-weight-bold',
            width: '8rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.limitedTime'),
            align: 'center',
            value: 'maxWorktime',
            class: 'font-weight-bold',
            width: '8rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.totalTimeWork'),
            align: 'center',
            value: 'totalWorkHourPerWeek',
            class: 'font-weight-bold',
            width: '7rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.overTime'),
            align: 'center',
            value: 'overTime',
            class: 'font-weight-bold',
            width: '11rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.actualTime'),
            align: 'center',
            value: 'workedTime',
            class: 'font-weight-bold',
            width: '11rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.allottedTime'),
            align: 'center',
            value: 'assignedTime',
            class: 'font-weight-bold',
            width: '8rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.planTime'),
            align: 'center',
            value: 'plannedTime',
            class: 'font-weight-bold',
            width: '8rem',
            sortable: false,
          },
          {
            text: this.$t('table.headers.laborSituation'),
            align: 'center',
            value: 'laborSituation',
            class: 'font-weight-bold',
            width: '6rem',
            sortable: false,
          },
        ],
        editedIndex: -1,
        items: [],
        search: '',
        loading: false,
        totalItems: 1,
        timeRange: '',
      }
    },
    computed: {
      ...get('parttimer', ['message', 'status', 'error', 'list28hManagement']),
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      search (value) {
        this.initialize(value)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      list28hManagement (value) {
        this.items = value
      },
    },
    created () {
      const currentDate = new Date()
      const firstday = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay()),
      ).toUTCString()
      const lastday = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6),
      ).toUTCString()
      this.timeRange =
        moment(firstday).format('YYYY/MM/DD') +
        ' - ' +
        moment(lastday).format('YYYY/MM/DD')
      this.$store.dispatch('parttimer/getList28hManagement')
    },
    mounted () {
    },
    methods: {
      initialize (search = '') {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        tmpOptions.offset = (tmpOptions.page - 1) * tmpOptions.limit

        if (search) {
          tmpOptions.keyword = search
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('parttimer/getList28hManagement', tmpOptions)
      },

      onSearch () {
        this.initialize(this.search)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      checkDetail (item) {
        this.$router.push('/parttimer-worktime-view')
        this.currentEmployee = {
          manageId: '',
          name: '',
          furiganaName: '',
          sex: null,
          dob: '',
        }

        this.userId = ''

        if (!item.manageId) return
        const payload = { id: item.manageId }
        this.$store.dispatch('parttimer/getParttimerEmployee', payload)
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
</style>
